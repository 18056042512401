/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-10 18:12:57
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-18 13:46:01
 */
export const columns = [
{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width:60,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'index'}
},
// {
//     title: '标签类型',
//     dataIndex: 'type',
//     key: 'type',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
{
    title: '标签名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '操作时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
